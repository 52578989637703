@import "~bootstrap/scss/bootstrap";
@import '~swiper/swiper-bundle.css';


@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamPro.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamPro-Medium.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamPro-Bold.ttf");
  font-weight: 700;
}
body {
  font-family: "Gotham Pro";
}
.color_black{
  color:#000;
  & * {
    color:#000;
  }
}
.fs-12 {
  font-size: 12px;
}
.fs-20{
  font-size: 20px;
}
.ul_nostyle{
  list-style: none;
  margin: 0;
  padding: 0;
}
a{
  color:#000;
  &:hover{
    opacity: 0.7;
  }
}
.round_with_icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  flex: 0 0 auto;
}
header.header {
  padding: 30px 0;
  .mobile_menu {
    margin-left: 15px;
    nav {
      a{
        margin-right: 15px;
      }
      ul {
        border-left: 1px solid #ccc;
        padding-left: 15px;
        a {
          .round_with_icon{
            margin-right: 5px;
          }
        }
      }
    }
  }
}
.mt-150 {
  margin-top: 150px;
}
.title {
  font-size: 40px;
  font-weight: bold;
}

.hidden-content{
  margin: 80px 0 145px 0;
  &.desktop{
    display: none;
  }
  .h3{
    font-size: 25px;
  }
  .right-absolute {
    position: absolute;
    bottom: 0;
    left: -40px;
    z-index: -1;
  }
  .purple-link {
    text-transform: uppercase;
    font-weight: 600;
  }
  article{
    ul{
      li{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .img-wrapper{
          width: 45px;
          flex: 0 0 auto;
        }
      }
    }
  }
}
.main_top_section {
  margin-top: 50px;
  h1 {
    font-size: 50px;
    .borders {
      background: #E1EEED;
      display: inline-block;
      padding: 4px 5px;
    }
  }
  .main-links-block{
    height: 280px;
  }
  p{
    margin: 30px 0 80px 0;
  }
}
.tabs_container .tab_content{
  display: none;
}

.tabs_container .tab_content.active{
  display: block;
}
.mt-10 {
  margin-top: 10px;
}
.bottom {
  .main-links-block {
    &.active {
      &:after {
        bottom: inherit;
        top:-47px;
        transform: translate(-40px, 50%) rotate(180deg);
      }
    }
  }

}
.main-links-block {
  position: relative;
  .mt-10{
    font-weight: bold;
  }
  &.active{
    &:before{
      content: '';
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      border: 3px solid #6f1e81;
      left: -5px;
      top:-5px;
      position: absolute;
    }
    &:after{
      content: '';
      width: 60px;
      height: 30px;
      border-top: solid 30px #6f1e81;
      border-left: solid 30px transparent;
      border-right: solid 30px transparent;
      position: absolute;
      bottom: -47px;
      left: 50%;
      transform: translate(-40px, -50%);
    }
    .front{
      position: relative;
      &:after{
        content:'';
        background: rgba(111, 30, 129, 0.7);
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top:0;
      }
      footer{
        z-index: 2;
        color:#fff;
        svg{
          g{
            rect:last-child{
              fill:#fff;
            }
          }
        }
      }
    }
  }
  .front{
    padding: 0;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    color: #000;
    font-weight: 400;
    top:0;
  }
  .back {
    text-align: left;
    padding: 20px;
    align-items: flex-start;
    top:0;
    .mt-10 {
      margin: 15px 0;
    }
    p{
      font-size: 16px;
    }
  }
  img.bg{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding:10px 15px;
    div {
      font-size: 23px;
    }
    .round_with_icon {
      width: 40px;
      height: 40px;
      border: 1px solid #6F1E81;
    }
  }
}
.purple {
  color: #6F1E81;
}
.main-big-banner-section {
  p{
    margin: 30px 0 50px 0;
  }
}
.fs-20{
  font-size: 20px;
}
.tabs {
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  &.fs-20{
    li{
      font-size: 20px;
    }
  }
  input[type="radio"] {
    display: none;
  }
  label {
    margin-bottom: 0;
    margin-right: 25px;
  }
  input:checked + label,label.active {
    color: #6F1E81;
    position: relative;
    &:after {
      content:'';
      position: absolute;
      bottom: -3px;
      width: 100%;
      border-bottom: 3px solid #6F1E81;
      left: 0;
    }
  }
  li{
    cursor: pointer;
    font-size: 30px;
    margin-right: 25px;
    font-weight: bold;
    &.active {
      color: #6F1E81;
      position: relative;
      a{
        color: #6F1E81;
      }
      &:after {
        content:'';
        position: absolute;
        bottom: -3px;
        width: 100%;
        border-bottom: 3px solid #6F1E81;
        left: 0;
      }
    }
  }
}
.main-products {
  article{
    margin: 30px 0 60px 0;
    .purple{
      font-weight: bold;
    }
    ul{
      font-weight: 600;
    }
  }
  .main-links-block{
    margin-bottom: 30px;
    height: 290px;
  }
  .display-none{
    display: none;
  }
}
.card-flip {
  cursor: pointer;
  height: 215px;
  perspective: 1000px;
  position: relative;
  background: #FFFFFF;
  display: block;
}
.front,
.back {
  display: flex;
  background-position: center;
  background-size: cover;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transition: ease-in-out 600ms;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.048951);
  img{
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.front {
  padding: 2rem;
  font-size: 1.618rem;
  font-weight: 600;
  color: #fff;
  overflow: hidden;
}
.front:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  background: #B192BD;
  opacity: 0.25;
  height: 10px;
  z-index: -1;
}
.card-flip:hover .front {
  transform: rotateY(180deg);
}
.card-flip:nth-child(even):hover .front {
  transform: rotateY(-180deg);
}

.back {
  transform: rotateY(-180deg);
  padding: 0 2em;
  background: #B192BD;
  color: #fff;
  font-size: 23px;
  text-align: center;
}
.card-flip:hover .back {
  transform: rotateY(0deg);
}
.card-flip:nth-child(even) .back {
  transform: rotateY(180deg);
}
.card-flip:nth-child(even) .back .button {
  background: linear-gradient(135deg, #e61a80, #e61a3c);
}
.card-flip:nth-child(even) .back .button:before {
  box-shadow: 0 0 10px 10px rgba(230, 26, 60, 0.25);
  background-color: rgba(230, 26, 60, 0.25);
}
.card-flip:nth-child(even):hover .back {
  transform: rotateY(0deg);
}
.our-partners {
  .title {
    margin-bottom: 40px;
  }
  .card-flip{
    .back{
      font-weight: bold;
    }
  }
}
.our-company {
  position: relative;
  .title {
    margin-bottom: 40px;
  }
  .right-bg{
    position: absolute;
    top:0;
    right: 15px;
  }
}
.mt-100{
  margin-top: 100px;
}
.purple-link{
  font-size: 14px;
  color:#fff;
  padding: 8px 20px;
  align-items: center;
  background:#6F1E81;
  margin-top: 40px;
  font-weight: 600;
  text-transform: uppercase;
  img{
    margin-left: 20px;
  }
}
.text-underline{
  text-decoration: underline;
}
.news-block {
  margin-bottom: 30px;
  .img-wrapper{
    display: block;
    height: 290px;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  footer{
    .d-flex{
      font-size: 16px;
      margin: 15px 0 10px 0;
      .tag{
        color:#B192BD;
      }
      .date{
        color:#A6A8A5;
      }
    }
    p{
      font-size: 23px;
      font-weight: 600;
    }
  }
}
.footer {
  .copyright{
    padding: 17px 15px;
    background: #DCDCDC;
  }
  .link_wr {
    a{
      font-weight: 600;
    }
  }
}
.clinic-top-section{
  h1{
    font-size: 50px;
    margin-bottom: 50px;
    font-weight: bold;
  }
  .clinic-border-content{
    margin-bottom: 30px;
    padding-left: 15px;
    position: relative;
    border-left: 2px solid #E1EEED;
    .h3{
      margin-bottom: 15px;
      font-weight: 600;
    }
    p{
      a{
        color:#6F1E81;
        font-weight: 600;
      }
    }
  }
}
.line-block{
  position: relative;
  padding-left: 100px;
  margin-bottom: 40px;
  .num{
    color:#fff;
    background: #B192BD;
    font-size: 12px;
    text-align: center;
    width: 29px;
    height: 29px;
    padding-top: 7px;
    border-radius: 50%;
    top:10px;
    left: 0;
    position: absolute;
  }
  .icon{
    position: absolute;
    top:40px;
    left: 40px;
  }
  .h3{
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
.easy-start{
  .chain-line {
    position: absolute;
    top: -25px;
    left: 15px;
  }
  .img-right{
    right: 0;
    position: absolute;
    top: 120px;
    z-index: -1;
  }
}
.fs-23{
  font-size: 23px;
}
.white-news{
  height: 295px;
  background: #fff;
  padding: 25px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.048951);
  h3{
    font-weight: bold;
  }
  footer{
    margin-top: auto;
    .date{
      color:#A6A8A5;
      font-weight: 600;
    }
    img{
      height: 25px;
    }
  }
}
.smi {
  .bottom-right{
    position: absolute;
    bottom: 0;
    right: 15px;
    z-index: -1;
  }
}
.form-section {
  .form-img {
    max-width: 555px;
    margin-top: 3rem;
  }
  .form-bg {
    position: absolute;
    right: 0;
    bottom: 30px;
    z-index: -1;
  }
  button.purple-link{
    margin-top: 0;
    padding: 13px 40px;
    border: 0;
    text-transform: uppercase;
    &:hover{
      opacity: 0.7;
    }
  }
  .description {
    color:#A6A8A5;
    font-size: 14px;
    margin-left: 20px;
  }
  .input-wrapper {
    margin-bottom: 30px;
    .label {
      font-weight: 600;
      margin-bottom: 15px;
    }
    .promocode-submit {
      position: absolute;
      cursor: pointer;
      right: 0;
      bottom:10px;
    }
    input,textarea{
      border: 0;
      outline: none;
      width: 100%;
      &::placeholder{
        color:#A6A8A5;
      }
    }
    textarea {
      height: 100px;
    }
    .nice-select {
      float: none;
      border: 0;
      padding: 0;
      height: 32px;
      line-height: 30px;
      font-size: 20px;
      .current {
        color:#A6A8A5;
      }
      &:after {
        border-bottom: 2px solid #6F1E81;
        border-right: 2px solid #6F1E81;
      }
      .list{
        width: 100%;
      }
    }
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
  }
}
.right-side-form {
  background: #FFFFFF;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.048951);
  padding: 60px 40px;
  .bb{
    border-bottom: 1px solid #ccc;
  }
}
.radio-inputs-wr {
  .radio-input {
    margin-bottom: 30px;
    font-weight: bold;
    input {
      display: none;
      &:checked + label {
        color:#6F1E81;
        &:after{
          border: 1px solid #6F1E81;
        }
        &:before{
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          top: 9px;
          left: 9px;
          background: #6F1E81;
        }
      }
    }
    label {
      position: relative;
      cursor: pointer;
      padding-left: 50px;
      font-size: 23px;
      margin-bottom: 0;
      &:after {
        content: '';
        position: absolute;
        width: 33px;
        height: 33px;
        top:0;
        left: 0;
        border-radius: 50%;
        border: 1px solid #DCDCDC;
      }
    }
  }
}
.hidden-case-content {
  padding-top: 100px;
  .title{
    margin-bottom: 0;
  }
  .place{
    color:#A6A8A5;
  }
  &.corporate {
    display: block;
    padding-top: 20px;
    .bb {
      border-bottom: 5px solid #E1EEED;
      margin: 20px 0 30px 0;
    }
    article.content {
      margin-top: 0;
    }
  }
  .count-text{
    font-size: 34px;
    font-weight: 600;
    margin-top: 20px;
    span{
      font-size: 50px;
      color:#6F1E81;
    }
  }
  .left-side{
    text-align: right;

    .hidden-bg{
      position: absolute;
      left: -60px;
      bottom: 70px;
      z-index: -1;
    }
  }
  article.content{
    margin-top: 50px;
  }
}
.doctor{
  margin-top: 80px;
  .img-wrapper{
    margin-right: 25px;
    width: 77px;
    height: 77px;
    flex: 0 0 auto;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .fs-20{
    font-weight: bold;
  }
  .position{
    color:#A6A8A5;
    margin-top: 5px;
    font-weight: 600;
  }
}
.cases {
  .hidden-case-content:not(.corporate){
    display: none;
  }
  .card-flip {
    & + .display-none {
      display: none!important;
    }
    &.active{
      .front {
        &:after{
          content: "";
          background: rgba(111,30,129,.7);
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
        }
        img{
          position: relative;
          z-index: 1;
        }
      }
      &:after {
        content: "";
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 3px solid #6f1e81;
        left: -5px;
        top: -5px;
        position: absolute;
      }
      &:before{
        content: "";
        width: 60px;
        height: 30px;
        border-top: 30px solid #6f1e81;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        position: absolute;
        bottom: -47px;
        left: 50%;
        transform: translate(-40px,-50%);
      }
    }
  }
}
.contacts{
  margin-top: 50px;
  h1{
    font-size: 50px;
    margin-bottom: 50px;
    font-weight: bold;
  }
  article{
    margin-bottom: 35px;
    .pagetitle{
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  .left-side{
    margin-left: 50px;
    .contact-info-block {
      background: #FFFFFF;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.048951);
      padding: 35px;
      margin-bottom: 20px;
      .pagetitle{
        font-weight: 600;
      }
      a {
        font-weight: bold;
      }
      .purple {
        display: inline-block;
        margin: 5px 0;
      }
    }
    .contact-left {
      position: absolute;
      bottom: -40px;
      left: 0;
      z-index: -1;
    }
  }
}
.about-top-section {
  .about-top-purple-info{
    font-weight: 600;
    background: #B192BD;
    padding: 20px 15px;
  }
  h1{
    font-weight: bold;
  }
}
.labs-about {
  .about-right {
    position: absolute;
    right: 15px;
    top:100%;
  }
}
.about-down-lab {
  font-size: 25px;
  font-weight: 600;
  padding: 45px 0;
}
.about-info-section {
  .bg-left{
    position: absolute;
    right: 15px;
    top:110px;
    z-index: -1;
  }
}
.licenses {
  .licenses-block {
    margin-bottom: 30px;
    .licenses-img-block{
      background: #FFFFFF;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.048951);
      padding: 20px;
      border-top: 10px solid #B192BD;
      height: 400px;
      img{
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    p {
      font-weight: 600;
      margin-top: 15px;
    }
  }
}
.our-command {
  .team-profile{
    margin-bottom: 40px;
    .position{
      color:#A6A8A5;
      font-weight: 600;
    }
    .name{
      margin-top: 20px;
      font-weight: bold;
    }
  }
}
.pres-partners {
  .logo-title{
    font-size: 30px;
  }
  .type-of-logo{
    height: 290px;
    position: relative;
    padding: 20px;
    border-top: 10px solid #B192BD;
    &.black{
      background: #000;
      footer{
        color:#fff;
      }
    }
    &.white{
      background: #fff;
    }
    footer{
      font-weight: 600;
      position: absolute;
      bottom: 20px;
      padding: 0 20px;
      width: 100%;
      left: 0;
    }
  }
}
.fs-30{
  font-size: 30px;
}
.about-download-section {
  .kit-right{
    position: absolute;
    left: 40px;
    top: 60px;
    z-index: -1;
  }
  .bg{
    max-width: 415px;
  }
}
h1 {
  font-size: 50px;
}
.ymaps-layers-pane {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}
.faq {
  .faq-block {
    border-top: 10px solid #B192BD;
    margin-bottom: 30px;
    article{
      display: none;
    }
    &.active{
      header {
        img{
          transform: rotate(-180deg);
        }
      }
    }
    header{
      padding: 25px;
      font-size: 23px;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
.news-page {
  .news-block{
    display: block;
    margin-bottom: 30px;
  }
  .back-link {
    img{
      transform: rotate(90deg);
    }
  }
  .article-tags {
    .tag{
      color: #B192BD;
    }
    .date {
      color:#A6A8A5;
    }
  }
}
.corporate-top-section {
  .left-side {
    padding-right: 80px;
    .right-bg-block {
      background: #B192BD;
      height: 100%;
      width: 65px;
      position: absolute;
      top:0;
      right: 0;
    }
  }
}
.personal-page {
  .footer-bg{
    .footer-bg-abs{
      position: absolute;
      right: 0;
      bottom: -20px;
    }
  }
  h1{
    font-weight: bold;
  }
}
.bold{
  font-weight: bold;
}
.semi-bold{
  font-weight: 600;
}
.z-index {
  z-index: 1;
}
.visible-sm {
  display: none;
}
.gens {
  .bg-img {
    max-width: 460px;
  }
  .bg {
    position: absolute;
    top:100px;
    left: 0;
    z-index: -1;
  }
}
#map{
  margin-top: 100px;
}
.grand-total{
  .price{
    font-weight: bold;
  }
}
.people-section {
  .white-news.people {
    min-height: 415px;
    height: auto;
    .purple{
      font-weight: 600;
    }
    .fs-20{
      font-weight: bold;
    }
    .position{
      font-size: 14px;
      font-weight: 600;
    }
  }
  .bottom-left {
    position: absolute;
    left: 15px;
    bottom: -80px;
    z-index: -1;
  }
  .doctor .img-wrapper {
    margin-right: 15px;
  }
}
[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  /* Firefox 3.5+ */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}
@media (max-width: 1330px) {
  header.header .mobile_menu nav a{
    font-size: 10px;
  }
  .hidden-content {

    .right-absolute {
      bottom: 60px;
    }
  }
  .faq,.news-page{
    .tabs{
      white-space: nowrap;
      overflow: auto;
    }
  }
  .easy-start.personal{
    .line-block{
      margin-bottom: 20px;
      .h3{
        margin-bottom: 15px;
      }
    }
  }
  .people-section{
    .doctor{
      .img-wrapper{
        width: 60px;
        height: 60px;
      }
      .fs-20{
        font-size: 18px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .card-flip{
    .back{
      font-size: 16px;
    }
  }
  .people-section{
    .doctor{
      margin-top: 40px;
      .img-wrapper{
        width: 50px;
        height: 50px;
      }
      .fs-20{
        font-size: 13px;
      }
      .position{
        font-size: 13px;
      }
    }
  }
  .gens{
    .bg-img{
      max-width: 100%;
    }
  }
  .easy-start.corporate {
    .line-block{
      margin-bottom: 20px;
    }
  }
  .cases{
    .tabs.fs-20 li{
      font-size: 18px;
    }
  }
  .clinic-top-section{
    h1{
      font-size: 40px;
    }
  }
  .md-none{
    display: none;
  }
  .footer{
    a.text-uppercase{
      font-size: 14px;
    }
  }
  header.header .mobile_menu nav a{
    font-size: 16px;
  }
  .mobile_menu{
    display: flex;
    align-items: center;
    .btn{
      margin-left: 60px;
    }
  }
  .burger-menu_lines::before,
  .burger-menu_lines::after,
  .burger-menu_lines {
    position: absolute;
    width: 18px;
    height: 2px;
    background-color: #000;
    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }
  .burger-menu_lines{
    position: relative;
    flex:0 0 auto;
    margin-right: 10px;
  }
  .burger-menu_lines::before {
    content: '';
    top: -7px;
  }
  .burger-menu_lines::after {
    content: '';
    top: 7px;
  }

  header.header.active{
    z-index: 11;
  }
  .burger-menu_lines.active {
    background-color: transparent;
  }
  .burger-menu_lines.active::before {
    top: 0;
    transform: rotate(45deg);
  }
  .burger-menu_lines.active::after{
    top: 0;
    transform: rotate(-45deg);
  }
  .burger-menu_overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
  }
  .burger-menu_overlay.active {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  body.active {
    overflow: hidden;
  }
  header.header{
    &.active {
      background: #fff;
    }
  }
  .mobile_menu {
    position: absolute;
    background: #fff;
    top: 100%;
    z-index: 2;
    width: 100%;
    left: 0;
    text-align: center;
    display: none;
    padding-bottom: 15px;
    &.active {
      display: block;
      margin-left: 0!important;
      nav{
        flex-direction: column;
        ul {
          padding-left: 0!important;
          border:0!important;
          flex-direction: column;
          width: 100%;
          li{
            width: 100%;
            a{
              justify-content: center;
            }
          }
        }
        a{
          padding: 15px;
          text-align: center;
          margin: 0;
          font-size: 16px;
          width: 100%;
          border-bottom: 1px solid rgba(0,0,0,0.1);
        }
      }
    }
  }
  .cases{
    .tabs{
      white-space: nowrap;
      overflow: auto;
    }
  }
  .form-section{
    margin-bottom: 60px;
    .form-img{
      max-width: 100%;
      margin-bottom: 30px;
    }
  }
  .about-download-section {
    .bg{
      max-width: 100%;
    }
  }
}
@media (min-width: 991px) {
  .main-products .display-none.hidden-content{
    display: none!important;
  }
}
@media (max-width: 991px) {
  .sm_none{
    display: none!important;
  }
  .people-section .white-news.people{
    min-height: auto;
  }
  .radio-inputs-wr {
    .radio-input {
      label{
        padding-top: 5px;
        font-size: 14px;
      }
    }
  }
  .gens{
    article.fs-20{
      margin: 0!important;
    }
  }
  .about-info-section{
    .bg{
      max-width: 100%;
    }
  }
  h1{
    font-size: 40px;
  }
  .about-top-section{
    article{
      margin: 30px 0!important;
    }
  }
  .contacts{
    .left-side{
      margin-left: 0;
      .contact-info-block{
        padding: 20px;
        .fs-23{
          font-size: 18px;
        }
      }
    }
  }
  .line-block{
    .h3{
      font-size: 18px;
      margin-bottom: 15px;
    }
    p{
      font-size: 14px;
    }
  }
  .title{
    font-size: 24px;
  }
  .easy-start{
    .title{
      margin-bottom: 30px!important;
    }
    .default-img{
      margin-bottom: 30px;
    }
  }
  .white-news{
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.048951);
    height: auto;
    margin-bottom: 30px;
  }
  .mt-150{
    margin-top: 30px;
  }
  .main-products .main-links-block.active + .display-none.hidden-content{
    display: block!important;
  }
  .main-products .display-none.hidden-content{
    margin: 40px 0 30px 0;
    article {
      margin-bottom: 0;
    }
    .title {
      font-size: 24px;
    }
    .fs-20 {
      font-size: 15px;
    }
    .btn-wr{
      flex-wrap: wrap;
      .purple-link{
        margin-top: 20px;
      }
    }
  }
  .copyright{
    .d-flex{
      flex-direction: column-reverse;
      a{
        margin: 5px 0;
      }
      img{
        margin-bottom: 10px;
      }
    }
  }
  .visible-sm{
    display: block;
  }
  .hidden-case-content{
    padding-top: 40px;
    .doctor{
      margin-top: 40px;
    }
    article.content{
      margin-top: 30px;
    }
    .count-text{
      font-size: 24px;
      span{
        font-size: 40px;
      }
    }
  }
  .main_top_section{
    .main-links-block{
      height: 200px;
      margin-bottom: 30px;
    }
    h1{
      font-size: 30px;
    }
    p{
      margin: 20px 0 30px 0;
    }
  }
  .fs-20{
    font-size: 18px;
  }
  .news-block{
    .img-wrapper{
      height: 200px;
    }
    footer{
      p{
        font-size: 16px;
      }
      .d-flex{
        font-size: 14px;
      }
    }
  }


}
@media (max-width: 767px) {
  .main-products .main-links-block{
    height: 250px;
  }
  .corporate-top-section{
    margin-top: 0!important;
  }
  .our-command{
    .team-profile{
      .name{
        font-size: 16px;
      }
      .position{
        font-size: 14px;
      }
      .img-wrapper{
        height: 200px;
        img{
          object-fit: contain;
          height: 100%;
          width: 100%;
          object-position: left;
        }
      }
    }
  }
  .right-side-form{
    padding: 30px 15px;
    margin-top: 20px;
  }
  .easy-start.corporate{
    .line-block{
      margin-bottom: 40px;
    }
  }
  .faq{
    .faq-block{
      header{
        padding: 15px 0;
        font-size: 16px;
      }
      article{
        font-size: 14px;
      }
    }
  }
  .form-section .form-img{
    margin-top: 0;
  }
  .our-partners{
    .card-flip{
      height: 200px;
      margin-bottom: 30px;
    }
  }
  .cases {
    .hidden-case-content.desktop.display-none:not(.corporate){
      display: none!important;
    }
    .card-flip.active+.display-none{
      display: block!important;
    }
  }
  .about-download-section{
    .text-right{
      margin: 30px 0 50px 0;
    }
  }
  .xs-none{
    display: none;
  }
  .tabs li{
    font-size: 20px;
  }
  .footer{
    .mt-5{
      margin-top: 15px!important;
    }
    .mb-5{
      margin-bottom: 15px!important;
    }
  }
  .personal-page{
    article.fs-20{
      margin: 15px 0!important;
    }
    .purple-link{
      margin: 20px 0 30px 0;
    }
  }
}
@media (max-width: 420px) {
  
}
@media only screen and (min-width: 641px){

}


@media (min-width: 576px){
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px){
  .container {
    max-width: 750px;
  }
}
@media (min-width: 992px){
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px){
  .container {
    max-width: 1170px;
  }
}
@media (min-width: 1330px){
  .container {
    max-width: 1300px;
  }
}